// index.js

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

const getQueryParam = (param) => {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get(param);
};

const restaurantId = getQueryParam('restaurantId');

if (restaurantId) {
  localStorage.setItem('restaurantId', restaurantId);
  console.log('Restaurant ID stored in localStorage:', restaurantId); // Optional: For debugging
} else {
  console.warn('No restaurantId found in the URL query parameters.');
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
