// src/Components/Navigation/PageType.js

import React from 'react';
import { motion } from 'framer-motion';
import { FaExpand, FaWindowRestore } from 'react-icons/fa';
import './css/pageType.css';

const PAGE_TYPES = [
  {
    id: 'full-screen',
    title: 'Full-Screen',
    icon: <FaExpand />,
  },
  {
    id: 'pop-up',
    title: 'Pop-Up',
    icon: <FaWindowRestore />,
  },
];

const PageTypeSelector = ({ selectedType, onSelect }) => {
  return (
    <div className='page-type-component'>
      <div className="page-type-selector">
        {PAGE_TYPES.map((type) => (
          <motion.div
            key={type.id}
            className={`selector-item ${selectedType === type.id ? 'active' : ''}`}
            onClick={() => onSelect(type.id)}
            tabIndex={0}
            role="button"
            aria-pressed={selectedType === type.id}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                onSelect(type.id);
              }
            }}
          >
            {selectedType === type.id && (
              <motion.div
                className="active-background"
                layoutId="active-background"
                transition={{ type: 'spring', stiffness: 500, damping: 30 }}
              />
            )}
            <div className="icon">{type.icon}</div>
            <span className="title">{type.title}</span>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default PageTypeSelector;
