// src/Components/ToggleButton/ToggleButton.js
import React from 'react';
import { FaChevronRight } from 'react-icons/fa';
import PropTypes from 'prop-types';
import './css/toggleButton.css';

function ToggleButton({ isOpen, onToggle }) {
  return (
	<div className='toggle-button-component'>
		<div
		className={`toggle-button ${isOpen ? 'open' : 'closed'}`}
		onClick={onToggle}
		aria-label={isOpen ? 'Close sidebar' : 'Open sidebar'}
		role="button"
		tabIndex={0}
		onKeyPress={(e) => {
			if (e.key === 'Enter' || e.key === ' ') {
			onToggle();
			}
		}}
		>
		<FaChevronRight className={`chevron ${isOpen ? 'rotated' : ''}`} />
		</div>
	</div>
  );
}

ToggleButton.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
};

export default ToggleButton;
