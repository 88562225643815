// src/Components/Navigation/ExitButton.js

import React from 'react';
import { FaChevronLeft } from 'react-icons/fa';
import './css/exitButton.css';

const ExitButton = ({ onExit }) => {
  const handleExit = () => {
    if (onExit && typeof onExit === 'function') {
      onExit();
    } else {
      if (window.history.length > 1) {
        window.history.back();
      } else {
        window.location.href = 'https://dashboard.reservaties.net/';
      }
    }
  };

  return (
    <div className='exit-button-component'>
      <button className="exit-button" onClick={handleExit} aria-label="Exit Page">
        <div className="icon-circle">
          <FaChevronLeft className="arrow-icon" />
        </div>
      </button>
    </div>
  );
};

export default ExitButton;
