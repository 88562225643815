// src/Views/BookingPage/index.js

import React, { useEffect, useState } from 'react';
import './css/bookingPage.css';

const BookingPage = ({ viewMode, refreshKey }) => {
  const [restaurantId, setRestaurantId] = useState(null);
  const [isRedirecting, setIsRedirecting] = useState(false);

  useEffect(() => {
    const storedRestaurantId = localStorage.getItem('restaurantId');
    if (storedRestaurantId) {
      setRestaurantId(storedRestaurantId);
    } else {
      // Redirect to dashboard if restaurantId is not found
      setIsRedirecting(true);
      window.location.href = 'https://dashboard.reservaties.net';
    }
  }, []);

  // Optionally, you can render a loading indicator while redirecting
  if (isRedirecting) {
    return (
      <div className="redirecting-message">
        Redirecting to your dashboard...
      </div>
    );
  }

  // Prevent rendering iframe until restaurantId is available
  if (!restaurantId) {
    return (
      <div className="loading-message">
        Loading booking information...
      </div>
    );
  }

  const srcUrl = `https://v1.reservaties.net/?restaurantId=${restaurantId}&viewMode=${viewMode}&loading=false`;

  return (
    <iframe
      key={refreshKey}
      src={srcUrl}
      className="view-component"
      title="Booking Iframe"
      allowFullScreen
      loading="lazy"
    />
  );
};

export default BookingPage;
