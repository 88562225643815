// src/Components/Modal/index.js

import React, { useState, useEffect } from 'react';
import { FaCheck } from 'react-icons/fa';
import useIsMobile from '../../Hooks/useIsMobile';
import './css/modal.css';

const Modal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const isMobile = useIsMobile();

  useEffect(() => {
    const hasVisited = localStorage.getItem('hasVisitedModal');
    if (!hasVisited) {
      setIsOpen(true);
    }
  }, []);

  const closeModal = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsOpen(false);
      localStorage.setItem('hasVisitedModal', 'true');
    }, 500);
  };

  if (!isOpen) return null;

  return (
    <div className='modal-component'>
      <div className={`overlay ${isClosing ? 'overlay-hidden' : ''}`}>
        <div className="modal">
          <FaCheck size={50} color="green" />
          <h2 className="heading">Welkom bij Preview Mode</h2>
          <p className="paragraph">
            {isMobile
              ? "Deze ervaring werkt het beste op een pc."
              : "Bekijk uw reservatiepagina hier."}
          </p>
          <button onClick={closeModal} className="standard-button">
          {isMobile
              ? "Toch Doorgaan"
              : "Verder"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
