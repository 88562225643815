// src/Components/Navigation/RefreshButton.js

import React from 'react';
import { FaRedo } from 'react-icons/fa';
import './css/refreshButton.css';

const RefreshButton = ({ onRefresh }) => {
  return (
	<div className='refresh-button-component'>
		<button className="refresh-button" onClick={onRefresh}>
		<div className="icon-circle">
			<FaRedo className="arrow-icon" />
		</div>
		</button>
	</div>
  );
};

export default RefreshButton;
