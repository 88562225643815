// src/Components/Sidebar/index.js

import React from 'react';
import './css/sidebar.css';

const Sidebar = ({ isOpen }) => {
  return (
	<div className='sidebar-component'>
		<div className={`sidebar ${isOpen ? 'open' : 'closed'}`}>
		<iframe
			src="https://dashboard.reservaties.net/#/?preview"
			title="Sidebar Iframe"
			frameBorder="0"
			className="sidebar-iframe"
		/>
		</div>
	</div>
  );
};

export default Sidebar;
