// src/Components/Navigation/Navigation.js

import React, { useState, useEffect } from 'react';
import ExitButton from './ExitButton/ExitButton';
import PageTypeSelector from './PageType/PageType';
import RefreshButton from './RefreshButton/RefreshButton';
import EmbedCodeModal from '../EmbedCodeModal/EmbedCodeModal';
import { FaCode } from 'react-icons/fa'; // Import the icon
import './css/navigation.css';

const Navigation = ({ setSelectedPageType, onRefresh }) => {
  const [selectedType, setSelectedType] = useState('full-screen');
  const [showEmbedModal, setShowEmbedModal] = useState(false);
  const [restaurantId, setRestaurantId] = useState(null);

  useEffect(() => {
    const storedRestaurantId = localStorage.getItem('restaurantId');
    if (storedRestaurantId) {
      setRestaurantId(storedRestaurantId);
    }
  }, []);

  const handleSelect = (type) => {
    setSelectedType(type);
    setSelectedPageType(type);
    console.log(`Selected Page Type: ${type}`);
  };

  const handleExit = () => {
    window.history.back();
  };

  const handleShowEmbed = () => {
    setShowEmbedModal(true);
  };

  const handleCloseEmbedModal = () => {
    setShowEmbedModal(false);
  };

  const embedCode = `
    <script src="https://static.reservaties.net/popup/my-widget-bundle.js"></script>
    <script>
      setTimeout(function() {
        var container = document.createElement('div');
        container.id = 'my-widget-container';
        document.body.appendChild(container);

        window.renderMyWidget('my-widget-container', {
          viewMode: 'popup',
          restaurantName: '',
          restaurantId: '${restaurantId || ''}'
        });
      }, 500);
    </script>
  `;

  return (
    <div className='navigation-component'>
      <nav className="navigation">
        <ExitButton onExit={handleExit} />
        <div className="navigation-buttons">
          <PageTypeSelector selectedType={selectedType} onSelect={handleSelect} />
          <RefreshButton onRefresh={onRefresh} />
          {selectedType === 'pop-up' && (
            <div className="refresh-button-component">
              <button className="refresh-button" onClick={handleShowEmbed}>
                <div className="icon-circle">
                  <FaCode className="arrow-icon" />
                </div>
              </button>
            </div>
          )}
        </div>
      </nav>
      <EmbedCodeModal isOpen={showEmbedModal} onClose={handleCloseEmbedModal} embedCode={embedCode} />
    </div>
  );
};

export default Navigation;
