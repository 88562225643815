// src/Components/EmbedCodeModal/EmbedCodeModal.js

import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import './css/embedCodeModal.css';

const EmbedCodeModal = ({ isOpen, onClose, embedCode }) => {
  if (!isOpen) return null;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(embedCode);
    alert('Code gekopieerd naar het klembord!');
  };

  return (
    <div className="embed-code-modal-overlay" onClick={onClose}>
      <div className="embed-code-modal" onClick={(e) => e.stopPropagation()}>
        <h2>Code Installeren</h2>
		<p>Plaats deze code in de head sectie of embed sectie van uw website. Dit is bovenaan in de html code. Soms kan u dit ook bij integraties plaatsen.</p>
        <div className="code-container">
          <SyntaxHighlighter language="html" style={atomDark}>
            {embedCode}
          </SyntaxHighlighter>
          <button className="copy-button" onClick={copyToClipboard}>
            Kopiëren
          </button>
        </div>
        <button className="close-button" onClick={onClose}>Sluiten</button>
      </div>
    </div>
  );
};

export default EmbedCodeModal;
