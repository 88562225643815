// src/App.js
import React, { useState } from 'react';
import Navigation from '../Components/Navigation';
import BookingPage from '../Views/BookingPage';
import Modal from '../Views/Modal';
import Sidebar from '../Views/Sidebar';
import ToggleButton from '../Components/ToggleButton';
import useIsMobile from '../Hooks/useIsMobile';

import './css/app.css';
import './css/colors.css';
import './css/fonts.css';
import './css/buttons.css';

function App() {
  const [viewMode, setViewMode] = useState('full-screen');
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [refreshKey, setRefreshKey] = useState(0);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleRefresh = () => {
    setRefreshKey((prevKey) => prevKey + 1);
  };

  const isMobile = useIsMobile();

  return (
    <div className="app-container">
      {isMobile ? (
        <>
          <Navigation setSelectedPageType={setViewMode} onRefresh={handleRefresh} />
          <div className="mobile-main-content">
            <BookingPage viewMode={viewMode} refreshKey={refreshKey} />
            <Sidebar isOpen={true} />
          </div>
          <Modal />
        </>
      ) : (
        <>
          <Sidebar isOpen={isSidebarOpen} />
          <div className={`main-content ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
            <Navigation setSelectedPageType={setViewMode} onRefresh={handleRefresh} />
            <BookingPage viewMode={viewMode} refreshKey={refreshKey} />
            <Modal />
          </div>
          <ToggleButton isOpen={isSidebarOpen} onToggle={toggleSidebar} />
        </>
      )}
    </div>
  );
}

export default App;
